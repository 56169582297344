<script>
export default {
  name: "Showdown",
}
</script>

<template>
  <transition appear name="showdown" mode="out-in">
    <div class="area_show_down"></div>
  </transition>
</template>

<style scoped lang="scss">

.showdown-enter-active {
  transition: transform 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.showdown-enter, .scale-up-appear /* .fade-leave-active in <2.1.8 */
{
  transform-origin: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
}
</style>